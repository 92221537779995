import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import Navigation from './Navigation';
import './App.css';
import Home from './home';
import About from './about';
import Apply from './apply';
import Pricing from './pricing';
import logo from "./Logo3.png";


function App() {

  
const initialOptions = {
  "client-id": "AS9Zq5L4edD62vIJrkvCnEnOsWM-xFHp8fL_Dhdux_r8KjVaCLYLJRWI1XuXX5GAag-tthInfr8iBb__",
  currency: "USD",
  intent: "capture"
};

  return (
    <div className="App">
  <PayPalScriptProvider options={initialOptions}>

<BrowserRouter>



    
   <Routes>
    
   <Route path="/"  element={<Home/>}></Route>
   <Route path="/about"  element={<About/>}></Route>
 
   <Route path="/pricing"  element={<Pricing/>}></Route>

   <Route path="/apply"  element={<Apply/>}></Route>


</Routes>
       


</BrowserRouter >    </PayPalScriptProvider>
<div style={{display:"block", textAlign:"center"}}>
  <img src={logo} alt="Night Tremors Designs Logo" /> 
  <br></br><br></br>
</div>
    </div>
  );
}

export default App;
