import {React, useState, useEffect } from "react";
import {Link} from "react-router-dom";
import logo from "./Logo1.png";
import './home';
import "./about";
import "./pricing";
import "./apply";
import { getAuth} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import  "firebase/firestore";



const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyBOk57v8SSTVBmYo8hoKZ9RDk5QfbHjQPo",
  authDomain: "ntapp24.firebaseapp.com",
  projectId: "ntapp24",
  databaseURL: "https://ntapp24.firebaseio.com",
  storageBucket: "ntapp24.appspot.com",
  messagingSenderId: "705234714551",
  appId: "1:705234714551:web:060c649da29e57b09cf232",
  });

function Navigation () { 

  const [authStatus, setAuthStatus] = useState(false);
  const auth = getAuth(firebaseConfig);
  const [user] = useAuthState(auth);


  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user !=null) {
         firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL) 
         var user = firebase.auth().currentUser;
         setAuthStatus(true);
      }
      else if (user == null){
          setAuthStatus(false);
        
      }
    });
  }, []);
 

        return (


<nav className="navbar navbar-expand-lg bg-body-tertiary">

<div className="container-fluid">
<a className="navbar-brand"><img style={{width:"170px"}} src={logo} /></a>


  <div className="navbar-collapse" id="navbarSupportedContent">
    <div className="navbar-nav mr-auto mb-2 mb-lg-0">  
    <Link to="/" rel="canonical" className="nav-link">Home</Link>
<Link to="/about" className="nav-link">Night Tremors</Link>
<Link to="/pricing" className="nav-link">Web Design Plans</Link>
<Link to="/apply" className="nav-link" >Apply</Link>

    </div>
    </div>
    </div>
    </nav>
  
       
        );
    }

    export default Navigation;