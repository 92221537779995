import React from 'react';
import {Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Logo2 from "./Logo2.png";
import mask from "./mask.jpg";
import logo from "./X.png";
import Navigation from './Navigation';


function About() {

  let class1;
{window.innerWidth <= 415  ?  class1="box5" : class1="box2"}

  return (
<div style={{fontSize:"18px"}} >
<Navigation/>
    
 <div className='PageT3' >

 <div className='cards3'>

<div className='box2' >
<Image className='img2' style={{width:"99%"}} src={mask} alt="Night Tremors Designs Mask" />

  <p className='about1'>  
  <Image src={Logo2} /><br></br><br></br>
Night Tremors Designs is based in Forest Park, Georgia and was designed to create more accessible website designs
for individuals who need web design services that work for a variety of budgets. We aim to create a space for more unique designs 
that capture the attention of users and converts users into customers for business owners. We work hard to create a positive and 
original brand image for companies and personal, creative looks for portfolios. <br></br>
</p>
 </div>


 
</div>

<br></br>


<div className='cards3'>

   <div className={class1}>
    <br></br>
     <h3 style={{textAlign:"center"}}>Our Objectives</h3>
   
          <br></br>
          <img style={{width:"21px", height:"22px"}} src={logo} alt="Night Tremors Designs Logo" />  To make our plans more accessible<br></br>
          <img style={{width:"21px", height:"22px"}} src={logo} alt="Night Tremors Designs Logo" />  To make payment options more flexible <br></br>
          <img style={{width:"21px", height:"22px"}} src={logo} alt="Night Tremors Designs Logo" />  To make attractive & creative designs<br></br>
          <img style={{width:"21px", height:"22px"}} src={logo} alt="Night Tremors Designs Logo" />  To create a smooth design process<br></br>
          <img style={{width:"21px", height:"22px"}} src={logo} alt="Night Tremors Designs Logo" />  To negotiate & work with our clients <br></br>
          <img style={{width:"21px", height:"22px"}} src={logo} alt="Night Tremors Designs Logo" />  To present a functional & interactive UI<br></br>
            <br></br>
      </div>




</div>





<br></br>
<br></br>
<div className='cards3'>
<div className='box2' style={{marginBottom:"5rem", textAlign:"center"}}>
      <br></br>
 <h3>Contact Us</h3>
 
 <br></br> 
<p style={{fontSize:"17px", color: "#1eee60"}}>
<Link onClick={() => window.location = 'mailto:nt@support.nighttremorsdesigns.com'}><svg style={{color: "#1eee60"}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> </Link>nt@support.nighttremorsdesigns.com</p></div>
</div>
    </div>

</div>

  );
}

export default About;